import * as React from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col, Card, Button } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banners from "../components/banners"
import Form from "../components/form"
import Advantages from "../components/advantages"

const FranchisePage = () => {
  const data = useStaticQuery(graphql`
    query {
      api {
        banners(where: { sections: { slug: "franchise" } }) {
          id
          link
          link_type {
            slug
          }
          sections {
            slug
          }
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, height: 800, quality: 75)
              }
            }
          }
          image_mobile {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, height: 350, quality: 75)
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo title="Sistemas Fotovoltaicos" />
      <Banners data={data.api.banners} />
      <Container className="padding-top-lg half-padding-bottom">
        <Row className="title half-padding-bottom">
          <Col>
            <h1>Mercado de Energia Solar</h1>
          </Col>
        </Row>
        <Row className="justify-content-center mb-4 align-items-center">
          <Col className="text-center">
            <StaticImage
              src="../images/parallax2.png"
              alt="Foto"
              className="mb-3 rounded-circle"
              placeholder="tracedSVG"
              aspectRatio="1"
            />
          </Col>
          <Col
            md={9}
            data-sal="slide-left"
            data-sal-duration="500"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <p>
              Com um crescimento exponencial nos últimos anos, o mercado de
              energia solar tem se mostrado seguro e estável para investimentos
              no Brasil.
            </p>

            <p>
              Além disso, o setor recebe incentivos governamentais, como linhas
              de financiamentos de energia solar - para pessoas físicas e
              jurídicas - com prazos de pagamento e juros mais baixos.
            </p>

            <p>
              Quer conhecer melhor o mercado de energia solar? <br />
              <strong className="color-yellow">Seja um franqueado!</strong>
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="padding-bottom">
        <Row>
          <Col
            className="text-center"
            md={3}
            data-sal="fade"
            data-sal-duration="500"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <StaticImage
              src="../images/parallax2.png"
              alt="Ícone"
              placeholder="tracedSVG"
              className="mb-3"
              layout="fullWidth"
              aspectRatio="1.7"
            />
            <div>
              <h2 className="title-sm mt-3">Lorem ipsum</h2>
              <p className="text-sm">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </Col>
          <Col
            className="text-center"
            md={3}
            data-sal="fade"
            data-sal-duration="500"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <StaticImage
              src="../images/parallax2.png"
              alt="Ícone"
              placeholder="tracedSVG"
              className="mb-3"
              layout="fullWidth"
              aspectRatio="1.7"
            />
            <div>
              <h2 className="title-sm mt-3">Lorem ipsum</h2>
              <p className="text-sm">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </Col>
          <Col
            className="text-center"
            md={3}
            data-sal="fade"
            data-sal-duration="500"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <StaticImage
              src="../images/parallax2.png"
              alt="Ícone"
              placeholder="tracedSVG"
              className="mb-3"
              layout="fullWidth"
              aspectRatio="1.7"
            />
            <div>
              <h2 className="title-sm mt-3">Lorem ipsum</h2>
              <p className="text-sm">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </Col>
          <Col
            className="text-center"
            md={3}
            data-sal="fade"
            data-sal-duration="500"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <StaticImage
              src="../images/parallax2.png"
              alt="Ícone"
              placeholder="tracedSVG"
              className="mb-3"
              layout="fullWidth"
              aspectRatio="1.7"
            />
            <div>
              <h2 className="title-sm mt-3">Lorem ipsum</h2>
              <p className="text-sm">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Advantages />
      <Container className="padding-top-lg padding-bottom">
        <Row className="title half-padding-bottom">
          <Col>
            <h1 className="mb-2">Modelos de negócio</h1>
          </Col>
        </Row>
        <Row className="justify-content-center plans">
          <Col md={11}>
            <Row>
              <Col
                md={4}
                className="py-3"
                data-sal="flip-right"
                data-sal-duration="1000"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                <Card className="bg-light-grey">
                  <div className="card-thumb">
                    <StaticImage
                      src="../images/parallax2.png"
                      alt="Imagem modelos de negócio"
                      layout="fullWidth"
                      placeholder="tracedSVG"
                      aspectRatio="1.7"
                    />
                  </div>
                  <Card.Body className="text-center">
                    <Card.Title className="text-uppercase">
                      Nome do modelo
                    </Card.Title>
                    <Card.Text>Descrição das vantagens</Card.Text>
                    <Card.Text>Descrição das vantagens</Card.Text>
                    <Card.Text>Descrição das vantagens</Card.Text>
                    <Card.Text>Descrição das vantagens</Card.Text>
                    <Card.Text>Descrição das vantagens</Card.Text>
                    <Card.Text>Descrição das vantagens</Card.Text>
                  </Card.Body>
                  <Card.Footer className="text-center">
                    <Button
                      variant="primary"
                      size="sm"
                      className="mb-2"
                      onClick={() => navigate("/contato")}
                    >
                      Saiba mais
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
              <Col
                md={4}
                data-sal="flip-right"
                data-sal-duration="1200"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                <Card className="bg-light-grey h-100">
                  <div className="card-thumb">
                    <StaticImage
                      src="../images/parallax2.png"
                      alt="Imagem modelos de negócio"
                      layout="fullWidth"
                      placeholder="tracedSVG"
                      aspectRatio="1.7"
                    />
                  </div>
                  <Card.Body className="text-center">
                    <Card.Title className="text-uppercase">
                      Nome do modelo
                    </Card.Title>
                    <Card.Text>Descrição das vantagens</Card.Text>
                    <Card.Text>Descrição das vantagens</Card.Text>
                    <Card.Text>Descrição das vantagens</Card.Text>
                    <Card.Text>Descrição das vantagens</Card.Text>
                    <Card.Text>Descrição das vantagens</Card.Text>
                    <Card.Text>Descrição das vantagens</Card.Text>
                  </Card.Body>
                  <Card.Footer className="text-center">
                    <Button
                      variant="primary"
                      size="sm"
                      className="mb-2"
                      onClick={() => navigate("/contato")}
                    >
                      Saiba mais
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
              <Col
                md={4}
                className="py-3"
                data-sal="flip-right"
                data-sal-duration="1000"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                <Card className="bg-light-grey">
                  <div className="card-thumb">
                    <StaticImage
                      src="../images/parallax2.png"
                      alt="Imagem modelos de negócio"
                      layout="fullWidth"
                      placeholder="tracedSVG"
                      aspectRatio="1.7"
                    />
                  </div>
                  <Card.Body className="text-center">
                    <Card.Title className="text-uppercase">
                      Nome do modelo
                    </Card.Title>
                    <Card.Text>Descrição das vantagens</Card.Text>
                    <Card.Text>Descrição das vantagens</Card.Text>
                    <Card.Text>Descrição das vantagens</Card.Text>
                    <Card.Text>Descrição das vantagens</Card.Text>
                    <Card.Text>Descrição das vantagens</Card.Text>
                    <Card.Text>Descrição das vantagens</Card.Text>
                  </Card.Body>
                  <Card.Footer className="text-center">
                    <Button
                      variant="primary"
                      size="sm"
                      className="mb-2"
                      onClick={() => navigate("/contato")}
                    >
                      Saiba mais
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="half-padding-top padding-bottom">
        <Row className="title half-padding-bottom text-center justify-content-center">
          <Col md={10}>
            <h1>Quero mais informações</h1>
            <p>
              Momento oportuno para você fazer parte desse setor que cresce
              mesmo em meio aos períodos de crise.
            </p>
            <p>
              Preencha seus dados e entraremos em contato para sanar dúvidas e
              questionamentos.
            </p>
            <p>Venha fazer parte da PURAGRID você também!</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={10}>
            <Form />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default FranchisePage
