import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Parallax } from "react-parallax"
import SwiperCore, { Navigation, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import bgImage from "../images/parallax2.png"

require("swiper/swiper.scss")
require("swiper/components/navigation/navigation.scss")

SwiperCore.use([Navigation, Autoplay])

export default function CtaComponent() {
  return (
    <Parallax blur={3} bgImage={bgImage} bgImageAlt="" strength={200}>
      <Container className="half-padding-top half-padding-bottom advantages">
        <Row className="justify-content-center text-center">
          <Col md={8} xs={12} className="pb-3 title">
            <h1 className="mb-4 text-white">Vantagens de ser um franqueado</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="swiper-button-prev" />
            <div className="swiper-button-next" />
            <Swiper
              spaceBetween={15}
              slidesPerView={3}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              breakpoints={{
                240: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 3,
                },
              }}
              className="advantages-slider"
            >
              <SwiperSlide key="0">
                <div className="bg-white p-4 h-100">
                  <h1 className="color-yellow mt-4">#1</h1>
                  <Row>
                    <Col md={10}>
                      <h3 className="text-uppercase">
                        Mercado em expansão no Brasil
                      </h3>
                    </Col>
                  </Row>
                </div>
              </SwiperSlide>
              <SwiperSlide key="1">
                <div className="bg-white p-4 h-100">
                  <h1 className="color-yellow mt-4">#2</h1>
                  <Row>
                    <Col md={10}>
                      <h3 className="text-uppercase">
                        Treinamento especializado
                      </h3>
                    </Col>
                  </Row>
                </div>
              </SwiperSlide>
              <SwiperSlide key="2">
                <div className="bg-white p-4 h-100">
                  <h1 className="color-yellow mt-4">#3</h1>
                  <Row>
                    <Col md={10}>
                      <h3 className="text-uppercase">
                        Melhores materiais e fornecedores
                      </h3>
                    </Col>
                  </Row>
                </div>
              </SwiperSlide>
              <SwiperSlide key="3">
                <div className="bg-white p-4 h-100">
                  <h1 className="color-yellow mt-4">#4</h1>
                  <Row>
                    <Col md={10}>
                      <h3 className="text-uppercase">
                        Sistema de gestão completo
                      </h3>
                    </Col>
                  </Row>
                </div>
              </SwiperSlide>
              <SwiperSlide key="4">
                <div className="bg-white p-4 h-100">
                  <h1 className="color-yellow mt-4">#5</h1>
                  <Row>
                    <Col md={10}>
                      <h3 className="text-uppercase">
                        Empresa já consolidada no mercado
                      </h3>
                    </Col>
                  </Row>
                </div>
              </SwiperSlide>
              <SwiperSlide key="5">
                <div className="bg-white p-4 h-100">
                  <h1 className="color-yellow mt-4">#6</h1>
                  <Row>
                    <Col md={10}>
                      <h3 className="text-uppercase">Alta rentabilidade</h3>
                    </Col>
                  </Row>
                </div>
              </SwiperSlide>
              <SwiperSlide key="6">
                <div className="bg-white p-4 h-100">
                  <h1 className="color-yellow mt-4">#7</h1>
                  <Row>
                    <Col md={10}>
                      <h3 className="text-uppercase">Garantia de qualidade</h3>
                    </Col>
                  </Row>
                </div>
              </SwiperSlide>
              <SwiperSlide key="7">
                <div className="bg-white p-4 h-100">
                  <h1 className="color-yellow mt-4">#8</h1>
                  <Row>
                    <Col md={10}>
                      <h3 className="text-uppercase">
                        Apoio e suporte integral
                      </h3>
                    </Col>
                  </Row>
                </div>
              </SwiperSlide>
            </Swiper>
          </Col>
        </Row>
      </Container>
    </Parallax>
  )
}
